
import DropDownButton from "@/components/DropDownButton"
import ExportColumnsSelector from "@/components/gateReport/ExportColumnsSelector"
import { AiApi } from "@evercam/shared/api/aiApi"
import { mapStores } from "pinia"
import { useGateReportStore } from "@/stores/gateReport"
import { useProjectStore } from "@/stores/project"
import { AnalyticsEvent, downloadTypes } from "@evercam/shared/types"
export default {
  components: { DropDownButton, ExportColumnsSelector },
  data() {
    return {
      totalDownloadProgress: 0,
      dialog: false,
      selectedFileType: null,
    }
  },
  computed: {
    specialSmWidth() {
      let width = this.$vuetify.breakpoint.width

      return width > 599 && width < 700
    },
    ...mapStores(useGateReportStore, useProjectStore),
    fileTypes() {
      let fileTypes = [
        {
          name: "CSV (*.csv) ",
          value: downloadTypes.csv,
          logo: require("~/assets/img/csv.png"),
        },
      ]

      if (!this.gateReportStore.isAnprLegacy) {
        fileTypes = [
          ...fileTypes,
          {
            name: "PDF (*.pdf)",
            value: downloadTypes.pdf,
            logo: require("~/assets/img/pdf.png"),
          },
        ]
      }

      return fileTypes
    },
    showExportColumnsSelector() {
      return (
        (this.gateReportStore.showAnprBasedGateReport ||
          this.gateReportStore.showLegacyGateReport) &&
        !this.gateReportStore.isAnprLegacy
      )
    },
  },
  methods: {
    handleFileTypeSelection({ value: fileType }) {
      if (this.showExportColumnsSelector) {
        this.dialog = true
        this.selectedFileType = fileType

        return
      }

      this.exportAs({ fileType })
    },
    async exportAs({ fileType, columns }) {
      try {
        this.totalDownloadProgress = 0
        this.gateReportStore.isDownloadingEvents = true
        this.$setTimeout(this.requestExportProgress, 1000)
        const response = await this.gateReportStore.requestExport({
          fileType,
          columns,
        })

        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute(
          "download",
          `Gate-report-events-${this.gateReportStore.selectedDate}.${fileType}`
        )
        document.body.appendChild(link)
        link.click()
        const events = {
          [downloadTypes.pdf]: AnalyticsEvent.gateReportExportPdf,
          [downloadTypes.csv]: AnalyticsEvent.gateReportExportCsv,
        }
        if (fileType in events) {
          this.$analytics.saveEvent(events[fileType])
        }
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.gate_report.export.download_failed"),
          error,
        })
      } finally {
        this.gateReportStore.isDownloadingEvents = false
        this.selectedFileType = null
        this.dialog = false
      }
    },
    async requestExportProgress() {
      try {
        if (!this.gateReportStore.showLegacyGateReport) {
          return
        }
        const projectExid = this.projectStore.selectedProjectExid
        const payload = {
          exportDate: this.gateReportStore.selectedDate,
        }
        const { progress } = await AiApi.gateReport.exportEventsProgress(
          projectExid,
          payload
        )
        this.totalDownloadProgress = progress
        if (
          (progress && progress >= 100) ||
          !this.gateReportStore.isDownloadingEvents
        ) {
          return
        }

        this.$setTimeout(this.requestExportProgress, 1000)
      } catch (error) {
        console.error("error on fetch progress state ....")
      }
    },
  },
}
